
  export default {
    props: {
      user: Object,
      size: {
        type: String,
        default: '2rem',
      },
    },
    computed: {
      fullName() {
        return `${this.user.firstName} ${this.user.lastName}`;
      },
      nameFirstLetters() {
        return this.fullName.match(/\b(\w)/g).join('');
      },
      avatarSrc() {
        const { locationResized } = this.user.photo || {};
        return locationResized;
      },
    },
  };
